import React, { useState, useEffect, Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import IntegrationEdit from './IntegrationEdit'
import Icon from 'components/Icon'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import {
  Form,
  Col,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Alert,
} from 'reactstrap'

const slackAuthUrl = () => {
  const slackBaseUrl = window.config.slackBaseUrl
  const client_id = window.config.slackClientId
  const scopes = window.config.slackScopes

  const configProblems = []
  if (!slackBaseUrl) {
    configProblems.push(`field 'slackBaseUrl' missing from config.json`)
  }
  if (!client_id) {
    configProblems.push(`field 'slackClientId' missing from config.json`)
  }
  if (!scopes) {
    configProblems.push(`field 'slackScopes' missing from config.json`)
  }
  if (configProblems.length > 0) {
    const errorMessage = `CONFIG ERRORS: ${configProblems.join(', ')}`
    // eslint-disable-next-line no-console
    console.error(errorMessage)
    throw new Error(errorMessage)
  }

  const redirectUrl = `${window.location.origin}/App/DoSlackAuth`
  return `${slackBaseUrl}?client_id=${client_id}&scope=${scopes}&redirect_uri=${redirectUrl}&user_scope=`
}

const SlackForm = props => {
  const [title, setTitle] = useState(
    props.integration && props.integration.get('title')
      ? props.integration.get('title')
      : ''
  )
  const [description, setDescription] = useState(
    props.integration && props.integration.get('description')
      ? props.integration.get('description')
      : ''
  )
  const [tokenValue, setToken] = useState('')
  const [updating, setUpdating] = useState(props.updating)
  const [creating, setCreating] = useState(props.creating)
  const [slackWindow, setSlackWindow] = useState(null)

  const handleUpdateTokenValue = params => {
    if (
      !params ||
      !params.isTrusted ||
      params.origin !== window.location.origin
    ) {
      return
    }
    if (params.data.slack) {
      setToken(params.data.code)
    }
  }

  useEffect(() => {
    window.addEventListener('message', handleUpdateTokenValue, false)
    return () => {
      window.removeEventListener('message', handleUpdateTokenValue)
    }
  }, [])

  const instantCheck = () => {
    if (props.integration) {
      const propTitle = props.integration.get('title')
        ? props.integration.get('title')
        : ''
      const propDescription = props.integration.get('description')
        ? props.integration.get('description')
        : ''
      return propTitle !== title || propDescription !== description
    } else {
      return !!tokenValue
    }
  }

  const create = () => {
    if (instantCheck()) {
      setCreating(true)
      props.create({
        title,
        description,
        slack: {
          token: tokenValue,
        },
      })
    }
  }

  const update = () => {
    if (instantCheck()) {
      setUpdating(true)
      props.update({ title, description })
    }
  }

  useEffect(() => {
    if (creating && !props.creating && !props.error) {
      props.close()
    } else if (updating && !props.updating && !props.error) {
      props.close()
    } else if (props.error) {
      setUpdating(false)
      setCreating(false)
    }
  }, [props.creating, props.updating])

  useEffect(() => {
    if (slackWindow && tokenValue) {
      slackWindow.close()
    }
  }, [slackWindow, tokenValue])

  return (
    <Fragment>
      <ModalHeader>
        {props.integration ? (
          <FormattedMessage {...messages.UpdateSlackToken} />
        ) : (
          <FormattedMessage {...messages.AddFirstSlackToken} />
        )}
      </ModalHeader>
      <ModalBody>
        {props.error && <Alert color="danger">{props.error}</Alert>}
        <Form>
          <IntegrationEdit
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            loading={props.creating || props.updating}
          />
          {!props.integration && (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'auto 1fr',
              }}
            >
              <div>Authorize Slack App</div>
              <Col sm={10}>
                <Button
                  onClick={() => {
                    const openedWindow = window.open(slackAuthUrl(), '_blank')
                    setSlackWindow(openedWindow)
                  }}
                  disabled={props.creating || tokenValue || props.updating}
                  style={{ marginLeft: '1em' }}
                  color={tokenValue ? 'success' : 'secondary'}
                >
                  {tokenValue ? (
                    <Fragment>
                      <FormattedMessage {...messages.ConnectedButton} />
                      &nbsp;
                      <Icon fa name="check-circle" />
                    </Fragment>
                  ) : (
                    <FormattedMessage {...messages.ConnectButton} />
                  )}
                </Button>
              </Col>
            </div>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            columnGap: '1em',
            alignItems: 'center',
          }}
        >
          {props.isModal && (
            <TextLink
              disabled={props.creating || props.updating}
              onClick={props.close}
              color="primary"
            >
              Close
            </TextLink>
          )}
          <Button
            onClick={props.integration ? update : create}
            style={{ marginLeft: '1em' }}
            color="primary"
            disabled={props.creating || props.updating || !instantCheck()}
          >
            {props.creating || props.updating ? (
              <Icon fa spin name="sync" />
            ) : props.integration ? (
              <FormattedMessage {...messages.UpdateButton} />
            ) : (
              <FormattedMessage {...messages.AddFirstButton} />
            )}
          </Button>
        </div>
      </ModalFooter>
    </Fragment>
  )
}

SlackForm.propTypes = {
  loading: PropTypes.bool,
  create: PropTypes.func,
  update: PropTypes.func,
  error: PropTypes.string,
  creating: PropTypes.bool,
  updating: PropTypes.bool,
  close: PropTypes.func,
  integration: ImmutablePropTypes.map,
  isModal: PropTypes.bool,
}

export default SlackForm
