import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import { List } from 'immutable'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import { getIntegrations } from 'containers/PlatformSettingsData/actions'
import {
  selectIntegrations,
  selectIntegrationsLoading,
} from 'containers/PlatformSettingsData/selectors'
import RedirectWithoutPermission from 'containers/PermissionChecker/RedirectWithoutPermission'

import messages from './messages'
import SideTab from 'components/SideTab'
import ManageIntegration from './ManageIntegration'
import IntegrationCard from './IntegrationCard'
import SectionHeader from 'components/SectionHeader'
import { FormattedMessage } from 'react-intl'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { push } from 'connected-react-router'
import qs from 'query-string'
import uuid from 'uuid/v4'

export class PlatformSettings extends React.Component {
  constructor(props) {
    super(props)
    if (props.integrations.isEmpty()) {
      props.getIntegrations()
    }
  }

  styles = {
    container: {
      margin: '1em',
      height: '92%',
    },
    title: {
      fontSize: '1.2em',
    },
    cardList: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      rowGap: '1em',
      columnGap: '1em',
      height: '150px',
    },
  }

  renderIntegrations = () => {
    if (this.props.loadingIntegrations) {
      return <SquareLoadingAnimation />
    }

    const slackIntegrations = this.props.integrations.filter(
      int => int.get('type') === 'SLACK'
    ).size

    const serviceNowIntegrations = this.props.integrations.filter(
      int => int.get('type') === 'SERVICE_NOW'
    ).size

    const jiraIntegrations = this.props.integrations.filter(
      int => int.get('type') === 'JIRA'
    ).size

    return (
      <div key={`card-holder${uuid()}`} style={this.styles.cardList}>
        <IntegrationCard
          key={`slack-card${uuid()}`}
          type="slack"
          integrationCount={slackIntegrations}
          push={this.props.push}
        />
        <IntegrationCard
          key={`service-now-card${uuid()}`}
          type="service_now"
          integrationCount={serviceNowIntegrations}
          push={this.props.push}
        />
        <IntegrationCard
          key={`jira-card${uuid()}`}
          type="jira"
          integrationCount={jiraIntegrations}
          push={this.props.push}
        />
      </div>
    )
  }
  // types of integration!!!! ServiceNow, Jira, Slack
  getTabs = () => {
    return [
      {
        label: 'Integrations',
        node: this.renderIntegrations(),
      },
    ]
  }

  backToPS = () => {
    this.props.push({
      pathName: 'App/PlatformSettings',
    })
  }

  render() {
    const view = qs.parse(this.props.location.search).integrationType
    let integrations = List()

    if (view === 'slack') {
      integrations = this.props.integrations.filter(
        int => int.get('type') === 'SLACK'
      )
    } else if (view === 'service_now') {
      integrations = this.props.integrations.filter(
        int => int.get('type') === 'SERVICE_NOW'
      )
    } else if (view === 'jira') {
      integrations = this.props.integrations.filter(
        int => int.get('type') === 'JIRA'
      )
    }

    if (view) {
      return (
        <div style={this.styles.container}>
          <Fragment>
            <RedirectWithoutPermission permissionName={'edit.integrations'} />
            <Breadcrumb>
              <BreadcrumbItem
                style={{ cursor: 'pointer', color: this.props.theme.primary }}
                onClick={this.backToPS}
              >
                <FormattedMessage {...messages.SettingsTitle} />
              </BreadcrumbItem>
              <BreadcrumbItem>
                {view === 'slack'
                  ? `Slack`
                  : view === 'service_now'
                  ? `ServiceNow`
                  : 'Jira'}
              </BreadcrumbItem>
            </Breadcrumb>
            <ManageIntegration
              loadingIntegrations={this.props.loadingIntegrations}
              integrations={integrations}
              type={view}
            />
          </Fragment>
        </div>
      )
    }

    return (
      <div style={this.styles.container}>
        <Fragment>
          <RedirectWithoutPermission permissionName={'edit.integrations'} />
          <SectionHeader>
            <FormattedMessage {...messages.SettingsTitle} />
          </SectionHeader>
          <SideTab>{this.getTabs()}</SideTab>
        </Fragment>
      </div>
    )
  }
}

PlatformSettings.propTypes = {
  theme: themeShape,
  integrations: ImmutablePropTypes.list,
  getIntegrations: PropTypes.func,
  push: PropTypes.func,
  loadingIntegrations: PropTypes.bool,
  location: PropTypes.object,
}
const mapStateToProps = createStructuredSelector({
  integrations: selectIntegrations,
  loadingIntegrations: selectIntegrationsLoading,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getIntegrations,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, themeable)(PlatformSettings)
