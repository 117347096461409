import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Input, FormGroup, Label, Col } from 'reactstrap'

const IntegrationEdit = props => {
  return (
    <Fragment>
      <FormGroup row>
        <Label for="title" sm={2}>
          Title
        </Label>
        <Col sm={10}>
          <Input
            disabled={props.loading}
            value={props.title}
            onChange={params => props.setTitle(params.target.value)}
            name="title"
            id="title"
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="description" sm={2}>
          Description
        </Label>
        <Col sm={10}>
          <Input
            disabled={props.loading}
            value={props.description}
            type="description"
            onChange={params => props.setDescription(params.target.value)}
            name="description"
            id="description"
          />
        </Col>
      </FormGroup>
    </Fragment>
  )
}

IntegrationEdit.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  setTitle: PropTypes.func,
  setDescription: PropTypes.func,
}

export default IntegrationEdit
