import React from 'react'
import PropTypes from 'prop-types'
import BorderedCard from 'components/BorderedCard'
import Button from 'components/Button'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { startCase } from 'lodash'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import Icon from 'components/Icon'
import qs from 'query-string'

import { INTEGRATION_TYPES } from 'containers/PlatformSettingsData/constants'

const IntegrationCard = props => {
  const styles = {
    card: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr auto',
      rowGap: '1em',
    },
    topCard: {
      display: 'grid',
      gridTemplateColumns: '75px 1fr',
    },
    botCard: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
    count: {
      fontStyle: 'italic',
      fontWeight: '400',
      alignItems: 'center',
      display: 'flex',
    },
    title: {
      fontSize: '1.2em',
      fontWeight: 400,
    },
    besideIcon: {
      marginLeft: '1em',
    },
  }

  const handleManageIntegrations = type => {
    if (type === 'slack') {
      props.push({
        pathName: 'App/PlatformSettings/',
        search: qs.stringify({
          integrationType: INTEGRATION_TYPES.SLACK,
        }),
      })
    }
    if (type === 'service_now') {
      props.push({
        pathName: 'App/PlatformSettings/',
        search: qs.stringify({
          integrationType: INTEGRATION_TYPES.SERVICE_NOW,
        }),
      })
    }
    if (type === 'jira') {
      props.push({
        pathName: 'App/PlatformSettings/',
        search: qs.stringify({
          integrationType: INTEGRATION_TYPES.JIRA,
        }),
      })
    }
  }

  const getMessage = (count, type) => {
    if (type === 'slack') {
      return (
        props.integrationCount > 0 &&
        `Added to ${props.integrationCount} ${startCase(props.type)} Workspace${
          props.integrationCount > 1 ? 's' : ''
        }`
      )
    }
    if (type === 'service_now') {
      return (
        props.integrationCount > 0 &&
        `Added to ${props.integrationCount} ${startCase(props.type)} Platform${
          props.integrationCount > 1 ? 's' : ''
        }`
      )
    }
    if (type === 'jira') {
      return (
        props.integrationCount > 0 &&
        `Added to ${props.integrationCount} ${startCase(props.type)} Project${
          props.integrationCount > 1 ? 's' : ''
        }`
      )
    }
  }

  return (
    <BorderedCard style={styles.card}>
      <div style={styles.topCard}>
        <div style={{ fontSize: '4em' }}>
          {props.type === INTEGRATION_TYPES.SLACK ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                style={{ width: '1em', height: '1em' }}
                png
                type="slacklogo"
              />
            </div>
          ) : props.type === INTEGRATION_TYPES.SERVICE_NOW ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                style={{ width: '1em', height: '1em' }}
                png
                type="servicenowlogo"
              />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                style={{ width: '1em', height: '1em' }}
                png
                type="jiralogo"
              />
            </div>
          )}
        </div>
        <div style={styles.besideIcon}>
          <div style={styles.title}>{startCase(props.type)}</div>
          <div>
            {props.type === INTEGRATION_TYPES.SLACK ? (
              <FormattedMessage {...messages.SlackDescription} />
            ) : props.type === INTEGRATION_TYPES.SERVICE_NOW ? (
              <FormattedMessage {...messages.ServiceNowDescription} />
            ) : (
              <FormattedMessage {...messages.JiraDescription} />
            )}
          </div>
        </div>
      </div>
      <div style={styles.botCard}>
        <div style={styles.count}>
          {getMessage(props.integrationCount, props.type)}
        </div>
        <Button
          outline
          color="primary"
          onClick={() => handleManageIntegrations(props.type)}
        >
          {props.type === INTEGRATION_TYPES.SLACK ? (
            props.integrationCount < 1 ? (
              <FormattedMessage {...messages.AddSlackButton} />
            ) : (
              <FormattedMessage {...messages.ManageIntegrationButton} />
            )
          ) : props.type === INTEGRATION_TYPES.SERVICE_NOW ? (
            props.integrationCount < 1 ? (
              <FormattedMessage {...messages.AddServiceNowButton} />
            ) : (
              <FormattedMessage {...messages.ManageIntegrationButton} />
            )
          ) : props.integrationCount < 1 ? (
            <FormattedMessage {...messages.AddJiraButton} />
          ) : (
            <FormattedMessage {...messages.ManageIntegrationButton} />
          )}
        </Button>
      </div>
    </BorderedCard>
  )
}

IntegrationCard.propTypes = {
  type: PropTypes.string,
  integrationCount: PropTypes.number,
  theme: themeShape,
  push: PropTypes.func,
}

export default themeable(IntegrationCard)
