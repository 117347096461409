import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

export const selectPlatformSettingsDataDomain = state =>
  state.get('platformSettingsData', Map())

export const selectIntegrations = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('integrations') || List()
)

export const selectDeletingIntegrations = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('deletingIntegrations') || Map()
)

export const selectDeletingIntegrationError = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('deletingError')
)

export const selectCreatingIntegration = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('creatingIntegration')
)

export const selectIntegrationsLoading = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('loadingIntegrations')
)

export const selectIntegrationsError = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('getIntegrationsError')
)

export const selectCreatingIntegrationsError = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('creatingError')
)

export const selectUpdatingIntegrations = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('updatingIntegrations')
)

export const selectUpdatingIntegrationError = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('updatingIntegrationError')
)

export const selectAssigningIntegrations = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('assigningIntegrations')
)

export const selectDeletingIntegrationAssignments = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('deletingIntegrationAssignments')
)

export const selectUpdatingConfigs = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('updatingIntegrationConfigs')
)

export const selectUpdatingConfigsError = createSelector(
  selectPlatformSettingsDataDomain,
  state => state.get('updatingIntegrationConfigsError')
)
