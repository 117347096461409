import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { List } from 'immutable'

import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import {
  updateIntegration,
  deleteIntegration,
  deleteIntegrationAssignment,
  createIntegration,
  deleteIntegrationError,
} from 'containers/PlatformSettingsData/actions'
import {
  selectUpdatingIntegrations,
  selectUpdatingIntegrationError,
  selectDeletingIntegrationAssignments,
  selectDeletingIntegrations,
  selectCreatingIntegration,
  selectCreatingIntegrationsError,
  selectDeletingIntegrationError,
} from 'containers/PlatformSettingsData/selectors'
import {
  selectSwimlanes,
  selectSwimlanesLoading,
} from 'containers/SonraiData/selectors'

import messages from './messages'
import SideTab from 'components/SideTab'
import { FormattedMessage } from 'react-intl'
import { Modal, ModalHeader, ModalFooter, Alert } from 'reactstrap'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import DataTable from 'components/DataTable'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import BorderlessButton from 'components/BorderlessButton'
import Icon from 'components/Icon'
import qs from 'query-string'
import ServiceNowForm from './ServiceNowForm'
import SlackForm from './SlackForm'
import JiraForm from './JiraForm'

import Tooltip from 'components/Tooltip'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class ManageIntegration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      configModalOpen: false,
      currentTab: 0,
      editIntegration: null,
      deleteModalOpen: false,
      deleteSrn: null,
      addNew: false,
      shouldClearError: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.integrations.isEmpty() &&
      prevProps.updatingIntegrations.get(
        prevProps.integrations.getIn([prevState.currentTab, 'srn'], '')
      ) &&
      !this.props.updatingIntegrations.get(
        this.props.integrations.getIn([prevState.currentTab, 'srn'], '')
      )
    ) {
      this.closeConfigModal()
    }

    if (this.props.deletingError && !this.state.shouldClearError) {
      this.setState({ shouldClearError: true })
      this.clearErrorTimeout()
    }
  }

  clearErrorTimeout = () => {
    setTimeout(() => {
      this.props.deleteIntegrationError({ srn: '', error: null })
      this.setState({ shouldClearError: false })
    }, 4000)
  }

  styles = {
    container: {
      height: '100%',
      display: 'grid',
      gridTemplateRows: '1fr auto',
      rowGap: '2em',
    },
    title: {
      fontSize: '1.2em',
    },
    topHolder: {
      marginBottom: '1em',
    },
  }

  openConfigModal = integration => {
    if (integration) {
      this.setState({ editIntegration: integration })
    } else {
      this.setState({ editIntegration: null })
    }
    this.setState({ configModalOpen: true })
  }

  closeConfigModal = () => {
    this.setState({ configModalOpen: false })
    this.setState({ editIntegration: null })
  }

  openDeleteModal = srn => {
    this.setState({ deleteModalOpen: true, deleteSrn: srn })
  }

  closedDeleteModal = () => {
    this.setState({ deleteModalOpen: false, deleteSrn: null })
  }

  getSwimlaneData = integration => {
    const configs = integration.get('configs')
      ? integration.get('configs')
      : List()
    return configs
      .map(config => {
        let assignments = ''
        if (config.get('assignment')) {
          const swimlane = this.props.swimlanes.find(
            sl =>
              sl.get('srn') === config.getIn(['assignment', 0, 'SwimlaneSRN'])
          )
          if (swimlane) {
            assignments = swimlane.get('title')
          }
        }
        if (integration.get('type') === 'SLACK') {
          return {
            swimlane: assignments,
            triggers: config.getIn(['slack', 'actionTypes']),
            channels: config.getIn(['slack', 'channels']),
            status: config.get('status'),
            edit: config.getIn(['assignment', 0, 'SwimlaneSRN']),
            delete: config.get('srn'),
          }
        } else {
          return {
            swimlane: assignments,
            status: config.get('status'),
            edit: config.getIn(['assignment', 0, 'SwimlaneSRN']),
            delete: config.get('srn'),
          }
        }
      })
      .toJS()
  }

  deleteAssignment = data => {
    this.props.deleteIntegrationAssignment({ assignmentSrn: data.delete })
  }

  pushToSwombo = data => {
    this.props.push({
      pathname: '/App/SwimlaneDetails',
      search: qs.stringify({ srn: data.edit, tab: 'integrations' }),
    })
  }

  renderIntegration = integration => {
    if (this.props.loadingIntegrations || this.props.swimlanesLoading) {
      return (
        <div
          key={`${integration.get('srn')}-loader`}
          style={{ alignItems: 'center', display: 'flex', height: '100%' }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    }

    return (
      <div key={`${integration.get('srn')}-key`} style={this.styles.container}>
        {/* <div>
          <div style={this.styles.title}>Authorized Channels</div>
          <DataTable data={[]} />
        </div> */}
        <div>
          <div style={this.styles.topHolder}>
            {this.props.deletingError && (
              <Alert color="danger">{this.props.deletingError}</Alert>
            )}
            <div style={this.styles.title}>Swimlane Assignments</div>
          </div>
          <DataTable
            autosize={false}
            style={{ height: '94%' }}
            customColumnConfig={{
              swimlane: {
                flex: 1,
              },
              triggers: {
                flex: 1,
              },
              channels: {
                flex: 1,
              },
              status: {
                width: 90,
                minWidth: 90,
                aggFunc: null,
                enableRowGroup: false,
                menuTabs: [],
                pinned: 'left',
                suppressMenu: true,
                cellRendererFramework: params => {
                  if (!params.data || !params.data.status) {
                    return null
                  }
                  const {
                    data: { status },
                  } = params

                  if (status === 'LIVE') {
                    return (
                      <Tooltip
                        position="right"
                        trigger={'hover'}
                        anchor={
                          <Icon
                            fa
                            name="check-circle-solid"
                            color={this.props.theme.success}
                            style={{ fontSize: '1rem' }}
                          />
                        }
                        tooltipContent={
                          <div style={{ padding: '5px', fontSize: '0.9rem' }}>
                            Live
                          </div>
                        }
                      />
                    )
                  } else if (status === 'FAILED') {
                    return (
                      <Tooltip
                        position="right"
                        trigger={'hover'}
                        anchor={
                          <Icon
                            fa
                            name="exclamation-triangle-solid"
                            color={this.props.theme.failed}
                            style={{ fontSize: '1rem' }}
                          />
                        }
                        tooltipContent={
                          <div style={{ padding: '5px', fontSize: '0.9rem' }}>
                            Failed
                          </div>
                        }
                      />
                    )
                  } else {
                    return (
                      <Tooltip
                        position="right"
                        trigger={'hover'}
                        anchor={
                          <Icon
                            fa
                            name="exclamation-triangle-solid"
                            color={this.props.theme.neutralMedium}
                            style={{ fontSize: '1rem' }}
                          />
                        }
                        tooltipContent={
                          <div style={{ padding: '5px', fontSize: '0.9rem' }}>
                            Unknown
                          </div>
                        }
                      />
                    )
                  }
                },
              },
              edit: {
                width: 120,
                minWidth: 120,
                aggFunc: null,
                pinned: 'right',
                headerName: '',
                enableRowGroup: false,
                menuTabs: [],
                suppressMenu: true,
                cellRendererFramework: params => {
                  if (!params.data || !params.data.edit) {
                    return null
                  }
                  return (
                    <TextLink
                      color="primary"
                      style={{ color: this.props.theme.primary }}
                      onClick={() => this.pushToSwombo(params.data)}
                    >
                      Edit Settings
                    </TextLink>
                  )
                },
              },
              delete: {
                width: 120,
                minWidth: 120,
                aggFunc: null,
                pinned: 'right',
                headerName: '',
                enableRowGroup: false,
                menuTabs: [],
                suppressMenu: true,
                cellRendererFramework: params => {
                  if (!params.data) {
                    return null
                  }
                  return (
                    <TextLink
                      color="primary"
                      style={{ color: this.props.theme.primary }}
                      onClick={() => this.deleteAssignment(params.data)}
                      disabled={this.props.deletingAssignments.get(
                        params.data.srn
                      )}
                    >
                      Delete
                    </TextLink>
                  )
                },
              },
            }}
            data={this.getSwimlaneData(integration)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '1em',
          }}
        >
          <TextLink
            onClick={() => this.openConfigModal(integration)}
            color="primary"
            disabled={this.props.deletingIntegrations.get(
              integration.get('srn')
            )}
          >
            <FormattedMessage {...messages.EditIntegration} />
          </TextLink>
          <Button
            style={{ marginLeft: '1em' }}
            color="danger"
            onClick={() => this.openDeleteModal(integration.get('srn'))}
            disabled={this.props.deletingIntegrations.get(
              integration.get('srn')
            )}
          >
            <FormattedMessage {...messages.DeleteIntegration} />
          </Button>
        </div>
      </div>
    )
  }

  getAddSlack = (updating, isModal) => {
    const integration = this.props.integrations.get(this.state.currentTab)

    let error = this.props.creatingError ? this.props.creatingError : null
    if (!error && integration) {
      error = this.props.updatingError.get(integration.get('srn'))
        ? this.props.updatingError.get(integration.get('srn'))
        : null
    }

    return (
      <Fragment>
        <SlackForm
          close={this.closeConfigModal}
          update={this.updateSlackIntegration}
          create={this.addNewSlackIntengration}
          updating={updating}
          creating={this.props.creatingIntegration}
          error={error}
          integration={this.state.editIntegration}
          isModal={isModal}
        />
      </Fragment>
    )
  }

  getAddJira = (updating, isModal) => {
    const integration = this.props.integrations.get(this.state.currentTab)
    let error = this.props.creatingError ? this.props.creatingError : null
    if (!error && integration) {
      error = this.props.updatingError.get(integration.get('srn'))
        ? this.props.updatingError.get(integration.get('srn'))
        : null
    }

    return (
      <Fragment>
        <JiraForm
          close={this.closeConfigModal}
          update={this.updateJiraIntegration}
          create={this.addNewJiraIntegration}
          updating={updating}
          creating={this.props.creatingIntegration}
          error={error}
          integration={this.state.editIntegration}
          isModal={isModal}
        />
      </Fragment>
    )
  }

  getAddServiceNow = (updating, isModal) => {
    const integration = this.props.integrations.get(this.state.currentTab)
    let error = this.props.creatingError ? this.props.creatingError : null
    if (!error && integration) {
      error = this.props.updatingError.get(integration.get('srn'))
        ? this.props.updatingError.get(integration.get('srn'))
        : null
    }

    return (
      <Fragment>
        <ServiceNowForm
          close={this.closeConfigModal}
          update={this.updateServicenowCreds}
          create={this.addNewSNIntegration}
          updating={updating}
          creating={this.props.creatingIntegration}
          error={error}
          integration={this.state.editIntegration}
          isModal={isModal}
        />
      </Fragment>
    )
  }

  getTabs = () => {
    let tabs = this.props.integrations
      .map(int => ({
        label: int.get('title'),
        node: this.renderIntegration(int),
      }))
      .toJS()
    tabs.push({
      label: (
        <BorderlessButton
          color="primary"
          onClick={() => this.openConfigModal()}
        >
          <Icon fa name="plus" />
          &nbsp;Add New Integration
        </BorderlessButton>
      ),
      node: null,
    })
    return tabs
  }

  handleTabSwitch = index => {
    this.setState({ currentTab: index })
  }

  confirmDelete = () => {
    if (this.state.deleteSrn) {
      this.props.deleteIntegration({ srn: this.state.deleteSrn })
      this.closedDeleteModal()
    }
  }

  updateSlackIntegration = params => {
    if (this.props.integrations.get(this.state.currentTab)) {
      this.props.updateIntegration({
        srn: this.props.integrations.get(this.state.currentTab).get('srn'),
        type: 'slack',
        title: params.title,
        description: params.description,
      })
    }
  }

  updateJiraIntegration = params => {
    if (this.props.integrations.get(this.state.currentTab)) {
      const variables = {
        srn: this.props.integrations.get(this.state.currentTab).get('srn'),
        type: 'jira',
        title: params.title,
        description: params.description,
      }
      if (params.jira) {
        variables.jira = params.jira
      }
      this.props.updateIntegration(variables)
    }
  }

  updateServicenowCreds = params => {
    if (this.props.integrations.get(this.state.currentTab)) {
      const variables = {
        srn: this.props.integrations.get(this.state.currentTab).get('srn'),
        type: 'serviceNow',
        title: params.title,
        description: params.description,
      }
      if (params.serviceNow) {
        variables.serviceNow = params.serviceNow
      }
      this.props.updateIntegration(variables)
    }
  }

  addNewSlackIntengration = params => {
    const redirectUrl = `${window.location.origin}/App/DoSlackAuth`
    this.props.createIntegration({
      type: 'SLACK',
      title: params.title,
      description: params.description,
      slack: { ...params.slack, redirectUrl },
    })
  }

  addNewJiraIntegration = params => {
    this.props.createIntegration({
      type: 'JIRA',
      title: params.title,
      description: params.description,
      jira: params.jira,
    })
  }

  addNewSNIntegration = params => {
    this.props.createIntegration({
      type: 'SERVICE_NOW',
      title: params.title,
      description: params.description,
      serviceNow: params.serviceNow,
    })
  }

  render() {
    let updating = false
    if (this.state.configModalOpen) {
      updating = this.props.updatingIntegrations.get(
        this.props.integrations.getIn([this.state.currentTab, 'srn'])
      )
    }

    if (!this.props.loadingIntegrations && this.props.integrations.isEmpty()) {
      if (this.props.type === 'slack') {
        return <div>{this.getAddSlack(updating)}</div>
      } else if (this.props.type === 'service_now') {
        return <div>{this.getAddServiceNow(updating)}</div>
      } else if (this.props.type === 'jira') {
        return <div>{this.getAddJira(updating)}</div>
      }
    }

    return (
      <Fragment>
        <SideTab onTabSwitch={this.handleTabSwitch}>{this.getTabs()}</SideTab>
        {this.state.configModalOpen && (
          <Modal
            size="lg"
            isOpen={this.state.configModalOpen}
            toggle={this.closeConfigModal}
          >
            {this.props.type === 'slack'
              ? this.getAddSlack(updating, true)
              : this.props.type === 'service_now'
              ? this.getAddServiceNow(updating, true)
              : this.getAddJira(updating, true)}
          </Modal>
        )}
        {this.state.deleteModalOpen && (
          <Modal
            isOpen={this.state.deleteModalOpen}
            toggle={this.closedDeleteModal}
          >
            <ModalHeader>
              {`Confirm Deletion of ${this.props.integrations.getIn(
                [this.state.currentTab, 'title'],
                'Integration'
              )}`}
            </ModalHeader>
            <ModalFooter>
              <TextLink onClick={this.closedDeleteModal}>Cancel</TextLink>
              <Button onClick={this.confirmDelete} color="danger">
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </Fragment>
    )
  }
}

ManageIntegration.propTypes = {
  theme: themeShape,
  integrations: ImmutablePropTypes.list,
  swimlanes: ImmutablePropTypes.map,
  loadingIntegrations: PropTypes.bool,
  swimlanesLoading: PropTypes.bool,
  creatingIntegration: PropTypes.bool,
  updatingIntegrations: ImmutablePropTypes.map,
  deletingIntegrations: ImmutablePropTypes.map,
  deletingAssignments: ImmutablePropTypes.map,
  updateIntegration: PropTypes.func,
  deleteIntegration: PropTypes.func,
  deleteIntegrationAssignment: PropTypes.func,
  createIntegration: PropTypes.func,
  push: PropTypes.func,
  deleteIntegrationError: PropTypes.func,
  type: PropTypes.string,
  deletingError: PropTypes.string,
  creatingError: PropTypes.bool,
  updatingError: ImmutablePropTypes.map,
}

const mapStateToProps = createStructuredSelector({
  swimlanes: selectSwimlanes,
  updatingIntegrations: selectUpdatingIntegrations,
  swimlanesLoading: selectSwimlanesLoading,
  deletingIntegrations: selectDeletingIntegrations,
  deletingAssignments: selectDeletingIntegrationAssignments,
  creatingIntegration: selectCreatingIntegration,
  creatingError: selectCreatingIntegrationsError,
  updatingError: selectUpdatingIntegrationError,
  deletingError: selectDeletingIntegrationError,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createIntegration,
      updateIntegration,
      deleteIntegration,
      deleteIntegrationAssignment,
      deleteIntegrationError,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, themeable)(ManageIntegration)
