import { defineMessages } from 'react-intl'

export default defineMessages({
  SettingsTitle: {
    id: 'app.containers.UserManagement.settingsTitle',
    defaultMessage: 'Platform Settings',
  },
  SlackDescription: {
    id: 'app.containers.UserManagement.slackDescription',
    defaultMessage:
      'Slack is a communication platform that offers many IRC-style features, including persistent chat rooms (channels) organized by topic, private groups, and direct messaging.',
  },
  ServiceNowDescription: {
    id: 'app.containers.UserManagement.serviceNowDescription',
    defaultMessage:
      'ServiceNow is a cloud computing platform to help companies manage digital workflows for enterprise operations.',
  },
  JiraDescription: {
    id: 'app.containers.UserManagement.jiraDescription',
    defaultMessage:
      'Jira is a proprietary issue tracking product developed by Atlassian that allows bug tracking and agile project management.',
  },
  ManageIntegrationButton: {
    id: 'app.containers.UserManagement.manageIntegrationButton',
    defaultMessage: 'Manage Integration',
  },
  AddSlackButton: {
    id: 'app.containers.UserManagement.addSlackButton',
    defaultMessage: 'Add Slack Workspace',
  },
  AddServiceNowButton: {
    id: 'app.containers.UserManagement.addServiceNowButton',
    defaultMessage: 'Add ServiceNow Platform',
  },
  AddJiraButton: {
    id: 'app.containers.UserManagement.addJiraButton',
    defaultMessage: 'Add Jira Project',
  },
  UpdateSlackToken: {
    id: 'app.containers.UserManagement.updateSlackToken',
    defaultMessage: 'Update Slack Workspace',
  },
  AddFirstSlackToken: {
    id: 'app.containers.UserManagement.addFirstSlackToken',
    defaultMessage: 'Add Slack Workspace',
  },
  UpdateJiraProject: {
    id: 'app.containers.UserManagement.updateJiraProject',
    defaultMessage: 'Update Jira Project',
  },
  AddFirstJiraProject: {
    id: 'app.containers.UserManagement.addFirstJiraProject',
    defaultMessage: 'Add Jira Project',
  },
  AddFirstServiceNowCreds: {
    id: 'app.containers.UserManagement.addFirstServiceNowCreds',
    defaultMessage: 'Add ServiceNow Platform',
  },
  UpdateServiceNowCreds: {
    id: 'app.containers.UserManagement.updateServiceNowCreds',
    defaultMessage: 'Update ServiceNow Platform',
  },
  UpdateButton: {
    id: 'app.containers.UserManagement.update',
    defaultMessage: 'Update',
  },
  AddFirstButton: {
    id: 'containers.UserManagement.addFirstButton',
    defaultMessage: 'Add',
  },
  ConnectButton: {
    id: 'containers.UserManagement.connectButton',
    defaultMessage: 'Connect',
  },
  ConnectedButton: {
    id: 'containers.UserManagement.connectedButton',
    defaultMessage: 'Connected',
  },
  EditIntegration: {
    id: 'containers.UserManagement.editIntegration',
    defaultMessage: 'Configure Integration',
  },
  DeleteIntegration: {
    id: 'containers.UserManagement.deleteIntegration',
    defaultMessage: 'Delete Integration',
  },
})
