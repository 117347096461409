import React from 'react'
import PropTypes from 'prop-types'
// import ImmutablePropTypes from 'react-immutable-proptypes'
import { useState, useEffect } from 'react'
import BorderlessButton from 'components/BorderlessButton'
import BorderedCard from 'components/BorderedCard'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export const SideTab = props => {
  const [activeTab, setActiveTab] = useState(0)
  useEffect(() => {
    if (
      (props.children && !props.children[activeTab]) ||
      !props.children[activeTab].node
    ) {
      setActiveTab(0)
    }
  })

  const checkAndSetTab = index => {
    if (props.children[index].node) {
      setActiveTab(index)
    }
  }

  const styles = {
    container: {
      display: 'grid',
      gridTemplateColumns: '250px 1fr',
      height: '100%',
    },
    tabHolder: {
      height: '98%',
      overflowY: 'auto',
      marginTop: '1em',
    },
    tab: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '0.25em',
    },
    highlight: {
      backgroundImage: `linear-gradient(to right, ${props.theme.background}, ${props.theme.neutralLight})`,
    },
    contentHolder: {
      // backgroundImage: `linear-gradient(135deg, ${props.theme.neutralLight}, ${props.theme.background})`,
      height: '100%',
    },
  }

  if (props.children && props.children.length > 0) {
    return (
      <div style={styles.container}>
        <div style={styles.tabHolder}>
          {props.children.map((node, index) => {
            if (index === activeTab) {
              return (
                <div
                  style={{
                    ...styles.tab,
                    ...styles.highlight,
                  }}
                  key={`sideTabGuy-${index}`}
                >
                  <BorderlessButton
                    style={{
                      fontSize: '1.2em',
                      fontWeight: 400,
                      width: '100%',
                      textOverflow: 'ellipsis',
                      textAlign: 'right',
                      overflowX: 'hidden',
                    }}
                  >
                    {node.label}
                  </BorderlessButton>
                </div>
              )
            } else {
              return (
                <div style={styles.tab} key={`sideTabGuy-${index}`}>
                  <BorderlessButton
                    style={{
                      fontSize: '1.2em',
                      width: '100%',
                      textOverflow: 'ellipsis',
                      textAlign: 'right',
                      overflowX: 'hidden',
                    }}
                    onClick={() => {
                      checkAndSetTab(index)
                      if (props.onTabSwitch) {
                        props.onTabSwitch(index)
                      }
                    }}
                  >
                    {node.label}
                  </BorderlessButton>
                </div>
              )
            }
          })}
        </div>
        <BorderedCard style={styles.contentHolder}>
          {props.children[activeTab] && props.children[activeTab].node}
        </BorderedCard>
      </div>
    )
  } else return null
}

SideTab.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      node: PropTypes.node,
    })
  ),
  onTabSwitch: PropTypes.func,
  theme: themeShape,
}

export default themeable(SideTab)
