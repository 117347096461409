import React, { useState, useEffect, Fragment, useRef } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import IntegrationEdit from './IntegrationEdit'
import Icon from 'components/Icon'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import { debounce } from 'lodash'
import {
  Input,
  Form,
  FormGroup,
  Label,
  Col,
  ModalFooter,
  ModalBody,
  ModalHeader,
  FormFeedback,
  Alert,
} from 'reactstrap'

const JiraForm = props => {
  const [title, setTitle] = useState(
    props.integration && props.integration.get('title')
      ? props.integration.get('title')
      : ''
  )
  const [description, setDescription] = useState(
    props.integration && props.integration.get('description')
      ? props.integration.get('description')
      : ''
  )
  const [cloudInstanceUrl, setCloudInstanceUrl] = useState('')
  const [username, setUsername] = useState('')
  const [token, setToken] = useState('')
  const [updating, setUpdating] = useState(props.updating)
  const [creating, setCreating] = useState(props.creating)

  const [
    invalidCloudInstanceUrlMsg,
    setInvalidCloudInstanceUrlMessage,
  ] = useState(undefined)
  const [invalidUsernameMsg, setInvalidUsernameMessage] = useState(undefined)
  const [invalidTokenMessage, setInvalidTokenMessage] = useState(undefined)

  const firstUpdate = useRef(true)

  const instantCheck = () => {
    if (props.integration) {
      const propTitle = props.integration.get('title')
        ? props.integration.get('title')
        : ''
      const propDescription = props.integration.get('description')
        ? props.integration.get('description')
        : ''
      if (propTitle === title && propDescription === description) {
        if (
          cloudInstanceUrl.length > 0 ||
          username.length < 1 ||
          token.length < 1
        ) {
          return false
        } else {
          return true
        }
      }
      // only check creds if any cred fields are typed into
      if (
        cloudInstanceUrl.length > 0 ||
        username.length > 0 ||
        token.length > 0
      ) {
        return checkCredsValid()
      } else {
        return true
      }
    } else {
      return (
        cloudInstanceUrl.length > 0 &&
        username.length > 0 &&
        token.length > 0 &&
        /^[a-zA-Z0-9][a-zA-Z0-9-.]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(
          cloudInstanceUrl
        )
      )
    }
  }

  const clearWarnings = () => {
    setInvalidCloudInstanceUrlMessage(undefined)
    setInvalidUsernameMessage(undefined)
    setInvalidTokenMessage(undefined)
  }

  const checkCredsValid = () => {
    let okValidMaybe = true
    if (
      cloudInstanceUrl.length < 1 ||
      !/^[a-zA-Z0-9][a-zA-Z0-9-.]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(
        cloudInstanceUrl
      )
    ) {
      setInvalidCloudInstanceUrlMessage('Enter a valid Cloud Instance URL.')
      okValidMaybe = false
    } else {
      setInvalidCloudInstanceUrlMessage(false)
    }

    if (username.length < 1) {
      setInvalidUsernameMessage('Enter a username.')
      okValidMaybe = false
    } else {
      setInvalidUsernameMessage(false)
    }

    if (token.length < 1) {
      setInvalidTokenMessage('Enter a Token.')
      okValidMaybe = false
    } else {
      setInvalidTokenMessage(false)
    }
    return okValidMaybe
  }

  let checkIsValid = () => {
    let okValidMaybe = true
    if (props.integration) {
      const propTitle = props.integration.get('title')
        ? props.integration.get('title')
        : ''
      const propDescription = props.integration.get('description')
        ? props.integration.get('description')
        : ''
      if (propTitle === title && propDescription === description) {
        if (
          cloudInstanceUrl.length > 0 ||
          username.length < 1 ||
          token.length < 1
        ) {
          okValidMaybe = false
        } else {
          okValidMaybe = true
        }
      }
      // only check creds if any cred fields are typed into
      if (
        cloudInstanceUrl.length > 0 ||
        username.length > 0 ||
        token.length > 0
      ) {
        okValidMaybe = checkCredsValid()
      } else {
        clearWarnings()
      }
    } else {
      okValidMaybe = checkCredsValid()
    }
    return okValidMaybe
  }

  checkIsValid = debounce(checkIsValid, 1000, {
    leading: false,
    trailing: true,
  })

  const create = () => {
    if (instantCheck()) {
      setCreating(true)
      props.create({
        title,
        description,
        jira: {
          cloudInstanceUrl,
          username,
          token,
        },
      })
    }
  }

  const update = () => {
    if (instantCheck()) {
      setUpdating(true)
      const variables = {
        title,
        description,
      }
      if (
        cloudInstanceUrl.length > 1 &&
        username.length > 1 &&
        token.length > 1
      ) {
        variables.jira = {
          cloudInstanceUrl,
          username,
          token,
        }
      }
      props.update(variables)
    }
  }

  useEffect(() => {
    if (creating && !props.creating && !props.error) {
      props.close()
    } else if (updating && !props.updating && !props.error) {
      props.close()
    } else if (props.error) {
      setUpdating(false)
      setCreating(false)
    }
  }, [props.creating, props.updating])

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    checkIsValid()
  }, [cloudInstanceUrl, username, token, title, description])

  return (
    <Fragment>
      <ModalHeader>
        {props.integration ? (
          <FormattedMessage {...messages.UpdateJiraProject} />
        ) : (
          <FormattedMessage {...messages.AddFirstJiraProject} />
        )}
      </ModalHeader>
      <ModalBody>
        {props.error && <Alert color="danger">{props.error}</Alert>}
        <Form>
          <IntegrationEdit
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            loading={props.creating || props.updating}
          />
          {props.integration && (
            <Label for="credGroup">
              When updating an Integration leave credentials blank if you do not
              wish to change them.
            </Label>
          )}
          <FormGroup row>
            <Label for="cloudInstanceUrl" sm={2}>
              Cloud Instance URL
            </Label>
            <Col sm={10}>
              <Input
                invalid={!!invalidCloudInstanceUrlMsg}
                disabled={props.creating || props.updating}
                value={cloudInstanceUrl}
                onChange={params => setCloudInstanceUrl(params.target.value)}
                name="cloudInstanceUrl"
                id="cloudInstanceUrl"
                placeholder="Example: sonrai-security.atlassian.com"
              />
              <FormFeedback>{invalidCloudInstanceUrlMsg}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="username" sm={2}>
              Username
            </Label>
            <Col sm={10}>
              <Input
                invalid={!!invalidUsernameMsg}
                disabled={props.creating || props.updating}
                value={username}
                onChange={params => setUsername(params.target.value)}
                name="username"
                id="username"
              />
              <FormFeedback>{invalidUsernameMsg}</FormFeedback>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="token" sm={2}>
              Token
            </Label>
            <Col sm={10}>
              <Input
                invalid={!!invalidTokenMessage}
                disabled={props.creating || props.updating}
                value={token}
                type="password"
                onChange={params => setToken(params.target.value)}
                name="token"
                id="token"
              />
              <FormFeedback>{invalidTokenMessage}</FormFeedback>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            columnGap: '1em',
            alignItems: 'center',
          }}
        >
          {props.isModal && (
            <TextLink
              disabled={props.creating || props.updating}
              onClick={props.close}
              color="primary"
            >
              Close
            </TextLink>
          )}
          <Button
            onClick={props.integration ? update : create}
            style={{ marginLeft: '1em' }}
            color="primary"
            disabled={props.creating || props.updating || !instantCheck()}
          >
            {props.creating || props.updating ? (
              <Icon fa spin name="sync" />
            ) : props.integration ? (
              <FormattedMessage {...messages.UpdateButton} />
            ) : (
              <FormattedMessage {...messages.AddFirstButton} />
            )}
          </Button>
        </div>
      </ModalFooter>
    </Fragment>
  )
}

JiraForm.propTypes = {
  loading: PropTypes.bool,
  create: PropTypes.func,
  update: PropTypes.func,
  error: PropTypes.string,
  creating: PropTypes.bool,
  updating: PropTypes.bool,
  close: PropTypes.func,
  integration: ImmutablePropTypes.map,
  isModal: PropTypes.bool,
}

export default JiraForm
